<template>
  <div style="padding: 20px">
    <div style="margin-bottom: 20px">
      <el-radio-group v-model="duration" @change="handleChange">
        <el-radio-button label="daily">日维度</el-radio-button>
        <el-radio-button label="weekly">周维度</el-radio-button>
        <el-radio-button label="monthly">月维度</el-radio-button>
      </el-radio-group>
    </div>
    <el-table :data="list" border stripe>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
            :data="props.row.payment_channel_stats"
            border
            class="inner-table"
            style="width: 50%; margin-left: 50px;"
          >
            <el-table-column type="index" :index="indexMethod"> </el-table-column>
            <el-table-column
              v-for="col in innerColumns"
              :prop="col.id"
              :key="col.id"
              :label="col.label"
              :width="col.width"
            >
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        v-for="col in columns"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :width="col.width"
      >
      </el-table-column>
    </el-table>
    <div style="padding: 20px 0; text-align: right;">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getFinanceStats } from "@/api/nft-finance.js";
export default {
  data() {
    return {
      columns: [
        { id: "record_time", label: "统计时间" },
        { id: "trade_amount", label: "总交易CNY" },
        { id: "trade_count", label: "CNY交易笔数" },
        { id: "trade_fee_amount", label: "手续费" },
        { id: "trade_channel_fee_amount", label: "收取通道手续费" },
        { id: "copyright_fiat_amount", label: "著作权许可费" },
        { id: "create_or_transfer_fee_amount", label: "转让铸造" },
        { id: "transaction_fee_amount", label: "上链" },
        { id: "third_income_amount", label: "三方入账金额" },
        { id: "transfer_pay_amount", label: "提现/划转/直接支付金额" },
        { id: "withdrawl_fee_amount", label: "收取提现手续费" },
        { id: "coupon_amount", label: "返利返现支出" },
      ],
      innerColumns: [
        { id: "record_time", label: "统计时间" },
        { id: "payment_channel", label: "支付渠道" },
        { id: "amount", label: "交易金额" },
        { id: "count", label: "交易笔数" },
      ],
      list: [],
      page: 1,
      page_size: 20,
      total: 0,
      duration: "daily",
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      try {
        const postData = {
          page: this.page,
          page_size: this.page_size,
          duration: this.duration,
        };
        const { data } = await getFinanceStats(postData);
        this.list = data.data.items;
        this.total = data.data.total || 0;
      } catch (error) {
        console.log(error);
      }
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSizeChange(pageSize) {
      this.page_size = pageSize;
      this.fetchList();
    },
    handleChange() {
      this.page = 1;
      this.page_size = 20;
      this.fetchList();
    },
    indexMethod(index) {
      return index + 1;
    },
  },
};
</script>

<style lang="less">
.inner-table {
  .el-table__header {
    .el-table__cell {
      background: #f5f7fa;
    }
  }
}
</style>
